import {useEffect, useState} from "react";

import {getAllCheaterData, searchText} from "../js/utils.js";

import '../style.scss';

function App() {

    const [search, setSearch] = useState("");
    const [searchLimit, setSearchLimit] = useState(10);

    const [result, setResult] = useState([]);

    const [page, setPage] = useState(1);

    const maxPerPage = 30;

    function getLimitedResult() {
        const _result = [];
        for(let i = 0; i < searchLimit; i++) {
            _result.push(getAllCheaterData()[i]);
        }

        return _result;
    }

    function getSearchResult() {
        const searchedOutput = searchText(search, searchLimit);

        const _result = [];
        for(let i = 0; i < searchedOutput.length; i++) {
            _result.push(getAllCheaterData()[searchedOutput[i]]);
        }

        return _result;
    }

    function getPaginationData() {
        return result.slice((page - 1) * maxPerPage, (page - 1) * maxPerPage + maxPerPage);
    };

    function handleNextPage() {

        let maxPages = Math.ceil(result.length / maxPerPage);

        if(page === maxPages) {
            return;
        }

        let _p = page + 1;
        setPage(_p)
    }

    function handlePrevPage() {

        if(page === 1) {
            return;
        }

        let _p = page - 1;
        setPage(_p)
    }


    useEffect(() => {
        setResult(getLimitedResult());
    }, []);

    useEffect(() => {

        if(search.length > 0) {
            setResult(getSearchResult());
        } else {
            setResult(getLimitedResult());
        }

    }, [searchLimit]);

    useEffect(() => {

        if(search.length === 0) return;

        setPage(1);

        setResult(getSearchResult());
    }, [search]);

    return (

        <div className={"d-flex flex-column h-100"}>

            <div className="main-wrapper">
                <div className={"main-banner"}>
                    <h1>Tarkov Hackers</h1>
                    <h4>Easily search the publicly available list of hackers banned on Escape From Tarkov</h4>
                </div>

                <div className={"main-content"}>

                    <div className={"search-area mt-3 d-flex justify-content-between align-items-center"}>
                        <input type={"text"} onChange={(e) => setSearch(e.target.value)} value={search} placeholder={"Search name..."}/>
                        <div className={"search-options d-flex"}>
                            <div className="dropdown me-2">
                                <button className="button-primary dropdown-toggle" type="button" id="limit-btn"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    Limit {searchLimit}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="limit-btn">
                                    <li className="dropdown-item" onClick={() => setSearchLimit(10)}>10</li>
                                    <li className="dropdown-item" onClick={() => setSearchLimit(20)}>20</li>
                                    <li className="dropdown-item" onClick={() => setSearchLimit(50)}>50</li>
                                    <li className="dropdown-item" onClick={() => setSearchLimit(100)}>100</li>
                                </ul>
                            </div>

                            <div>
                                <i className="fa-solid fa-angles-left me-1"></i>
                                <i className="fa-solid fa-angle-left me-1" onClick={handlePrevPage}></i>
                                <span>{page} of {Math.ceil(result.length / maxPerPage)}</span>
                                <i className="fa-solid fa-angle-right ms-1" onClick={handleNextPage}></i>
                                <i className="fa-solid fa-angles-right ms-1"></i>
                            </div>
                        </div>


                    </div>

                    <hr />

                    <div className={"result-data"}>

                        {
                            result.length > 0 ?

                                <div className={"hacker d-flex justify-content-between"}>

                                    <div className={"name-header"}>
                                        Username
                                    </div>

                                    <div className={"reason-header"}>
                                        Reason
                                    </div>
                                </div>

                                :
                                <div className={"text-center"}></div>
                        }

                        {
                            getPaginationData().length > 0 ?
                                getPaginationData().map(d => (
                                    <div key={d.id} className={"hacker d-flex justify-content-between"}>

                                        <div className={"name"}>
                                            {d.name}
                                        </div>

                                        <div className={"reason"}>
                                            {d.reason}
                                        </div>
                                    </div>
                                ))

                                :
                                <div className={"text-center"}>Start by typing in a username</div>
                        }

                    </div>

                </div>

            </div>

            <footer className={"mt-auto text-center"}>
                Game content and materials are trademarks and copyrights of Battlestate Games and it's licensors. All rights reserved.
            </footer>

        </div>

    );
}

export default App;
