import {Index} from "flexsearch";

import cheater1 from "../data/cheaters-1.json";
import cheater2 from "../data/cheaters-2.json";
import cheater3 from "../data/cheaters-3.json";
import cheater4 from "../data/cheaters-4.json";
import cheater5 from "../data/cheaters-5.json";

const ALL_DATA = setupData();
const SearchIndex = new Index({tokenize: "full"});

ALL_DATA.forEach(d => {
    SearchIndex.add(d.id, d.name);
})

function setupData() {
    return [...cheater1, ...cheater2, ...cheater3, ...cheater4, ...cheater5].map((val, index) => {
        val.id = index;
        return val;
    });
}

export function getAllCheaterData() {
    return ALL_DATA;
}

export function searchText(text, limit) {
    return SearchIndex.search(text, limit);
}